@import url('https://rsms.me/inter/inter.css');

html,
body {
  font-size: 13px;
  font-family: 'Inter', sans-serif;
  letter-spacing: 0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
  min-width: 1100px;
  background-color: #f6f8fa;
  scrollbar-gutter: stable;
}
html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ul,
ol,
p,
label,
form,
input,
select,
textarea,
button,
td,
th,
hr,
blockquote,
code {
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
}
html,
body,
a,
button {
  color: #27313b;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}
button,
code,
input,
option,
select,
strong,
textarea,
th {
  font: inherit;
}
a,
button,
i,
img,
input,
label,
select,
textarea {
  display: block;
}
a {
  text-decoration: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
th {
  text-align: left;
}
img,
video {
  vertical-align: bottom;
}
img,
hr {
  border: none;
}
li {
  list-style: none;
}
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
textarea {
  resize: none;
}
::-webkit-input-placeholder {
  color: #d5d5d5;
  font-weight: 200;
  letter-spacing: 0.02em;
}
::-moz-placeholder {
  color: #d5d5d5;
  font-weight: 200;
  letter-spacing: 0.02em;
}
::-ms-input-placeholder {
  color: #d5d5d5;
  font-weight: 200;
  letter-spacing: 0.02em;
}
#container {
  width: 100%;
  height: 100%;
}
